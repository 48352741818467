// COLORS
// These match with Colors specified in app/assets/stylesheets/_settings.scss

export const orange = '#ef946c';
export const black = '#000';
export const green = '#537766';
export const red = '#a24936';

export const lightBlue = 'rgb(145, 181, 194)';
export const darkBlue = 'rgb(4, 46, 61)';
export const blue = '#3A6A7E';

export const cream = '#f5f6f0';
export const white = '#FFFFFF'

export const gray = '#DFDFDF';
export const lightGray = '#e6e6e6';
export const mediumGray = '#cacaca';
export const darkGray = '#666';


export const primary = darkBlue;
export const secondary = lightBlue;
export const success = green;
export const warning = orange;
export const alert = red;

export const cardColors = [
  lightBlue,
  green,
  darkBlue,
  blue,
  green,
  blue,
  lightBlue,
  green,
  blue,
  darkBlue
];

export const colorRef = {
  blue: blue,
  lightBlue: lightBlue,
  darkBlue: darkBlue,
  green: green,
};