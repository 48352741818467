import React from "react"

const Success = () => {

  return (
    <div id='success' className="lmt">
      <h2 className="text-center lmb">Thanks a lot for your response!</h2>
    </div>
  );

}

export default Success
