let loaded;
let height;

const sizeRecordHeight = () => {
  const records = document.querySelectorAll('.record-card')
  if(records){
    for(let i = 0; i < records.length; i++){
      const record = records[i];
      height = record.offsetWidth + 'px';
      record.style.height = height;
    }

    // Use loaded variable to determine whether to set listener
    // to avoid the listener calling itself again.
    if(!loaded) setResizeListener();
    loaded = true;
  }

  const addIdeaCard = document.querySelector('.add-idea-card');
  if(addIdeaCard) addIdeaCard.style.height = height;

  const draftAimsCard = document.querySelector('.draft-aims-card');
  if(draftAimsCard) {
    draftAimsCard.style.height = height;
    draftAimsCard.style.width = height;
  }
}


// resize record height on window resize
const setResizeListener = () => {
  window.addEventListener('resize', () => {
    sizeRecordHeight();
  })
}



document.addEventListener('turbolinks:load', () => {
  loaded = false;
  sizeRecordHeight()
})
