import React, {useState, useEffect} from "react";
import Rails from '@rails/ujs';
import Success from "./Success";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {faThumbsUp, faThumbsDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AimsResponse = () => {
  const [score, setScore] = useState(null);
  const [comment, setComment] = useState('');
  const [finished, setFinished] = useState(false);

  const scoreInput =  document.getElementById('aims_response_score');
  const commentInput = document.getElementById('aims_response_comment');

  useEffect(() => {
    scoreInput.value = score
  }, [score])

  useEffect(() => {
    commentInput.value = comment
  }, [comment])

  const handleSliderChange = (value) => {
    setScore(value);
  }

  const handleChange = e => {
    setComment(e.target.value);
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = document.getElementById('aims-response-form');
    Rails.fire(form, 'submit');

    // clear comment and score in state
    setScore(null);
    setComment('');
    setFinished(true);
  }

  const anyFieldHasValue = () =>{
    return (score || comment)? true : false
  }

  if(finished) {

    return (<Success />)

  } else {

    return(
      <section id="question-wrapper" className='mpt' >

        <label htmlFor="score" className="show-for-sr">How well do you think the draft action plan matches the challenges and aspirations?</label>

        <div className='slider-container flex-container align-middle mpl-medium-up mpr-medium-up lmb' >
          <FontAwesomeIcon icon={faThumbsDown} size={"2x"} className='warning slider-icon' />

          <Slider
            style={{
              padding: 0,
              marginRight: '1rem',
              marginLeft: '1rem',
              marginBottom: '0.7rem'
            }}
            min={1}
            max={3}
            defaultValue={null}
            onChange={handleSliderChange}
            handleStyle={[{height: '1.5rem',width: '1.5rem',}]}
            trackStyle={[{height: '1rem'}]}
            railStyle={{height: '1rem'}}
            dotStyle={{height: '1rem',width: '1rem', top: '0', marginLeft: '-0.5rem'}}
            value={score}
            marks={
              {
                1: {
                  label: <strong className="h3">1</strong>,
                },
                2: {
                  label: <strong className="h3">2</strong>,
                },
                3: {
                  label: <strong className="h3">3</strong>,
                }
              }
            }
          />

          <FontAwesomeIcon icon={faThumbsUp} size={"2x"} className='green slider-icon' />
        </div>

        {/*<h3 className='text-center primary slider-answer mmb'>{score}</h3>*/}

        <label htmlFor="comment" className="mmt bold">Do you have any further comments?</label>
        <textarea
          className='mmb'
          placeholder='Your comment here...'
          onChange={handleChange}
          value={comment}
          name="comment"
          id="comment"
          rows="3"
        />

        <button
          className="button small primary-bg large white nmb expanded"
          onClick={handleSubmit}
          // disabled={!anyFieldHasValue()}
          disabled // NOTE consultation now closed
        >
          Submit
        </button>

      </section>
    )
  }


}

export default AimsResponse;
