const openLinksInNewWindow = () => {

  const links = document.querySelectorAll('.trix-content a');

  links.forEach(link => {
    link.setAttribute('target', '_blank');
  })
}

document.addEventListener('turbolinks:load', () => {

  const trixContent = document.querySelector('.trix-content');
  if (trixContent) openLinksInNewWindow();

})