import * as vis from './visibility';

// add event listener on turbolinks:load
document.addEventListener('turbolinks:load', function() {
  
  // get #consultation-closed-popup
  var popup = document.getElementById('consultation-closed-popup');

  // add click listener to document
  document.addEventListener('click', function(event) {
    // get the element with the class slider-container
    var sliderContainer = document.querySelector('.slider-container');

    if(sliderContainer){
      // if the target is the sliderContainer or is contained within sliderContainer then fade in popup
      const isOrInsideSliderContainer = sliderContainer.contains(event.target) || sliderContainer === event.target;

      const isComment = event.target.id === 'comment'

      if (isOrInsideSliderContainer || isComment) {
        event.preventDefault();
        vis.show(popup);
      }
    }

    
  });
});
