const requireDataPolicyFieldToBeChecked = (e, data_policy_id) => {
  const dataPolicyCheckbox = document.getElementById(data_policy_id);

  if (e.target.value){
    dataPolicyCheckbox.required = true;
  } else {
    dataPolicyCheckbox.required = false;
  }
}

document.addEventListener("turbolinks:load", () => {
  // on idea new/edit page && for comment fort
  // get text input for @email
  // if value not null, make :data_policy checkbox required
  const ideaForm = document.getElementById('idea-form');
  const commentForm = document.getElementById('comment-form');

  if (ideaForm ){
    const emailTextInput = document.getElementById('email');
    emailTextInput.addEventListener('change', (e) => requireDataPolicyFieldToBeChecked(e, 'data_policy'))
  }

  if (commentForm ){
    const emailTextInput = document.getElementById('comment_email');
    if(!emailTextInput) return;
    emailTextInput.addEventListener('change', (e) => requireDataPolicyFieldToBeChecked(e, 'comment_data_policy'))
  }
});