import * as colors from "./colors";

let loaded;

const recordCardColors = () => {
  const records = document.querySelectorAll(".record-card");

  if (records && records.length > 0) {
    let colorSelection = [colors.cardColors].flat();
    while (records.length > colorSelection.length) {
      colorSelection.push(colors.cardColors);
      colorSelection = colorSelection.flat();
    }
    for (let i = 0; i < records.length; i++) {
      const record = records[i];

      // TODO refactor
      if (record.classList.contains("no-bg")) {
        const colorToAvoid = findColorClass(record.classList);
        if (colorSelection[i] === colors.colorRef[colorToAvoid]) {
          record.style.backgroundColor = "#fff";
        } else {
          record.style.backgroundColor = colorSelection[i];
        }
      } else {
        record.style.backgroundColor = colorSelection[i];
      }

      // check if background is not light-blue; if so change text to white
      if (record.style.backgroundColor != colors.lightBlue) {
        record.style.color = colors.white;
      }
    }

    setRemoteListenerForPaginateButtons();
  }
};

const findColorClass = (classList) => {
  for (let i = 0; i < classList.length; i++) {
    const klass = classList[i];
    if (klass.includes("no-bg-")) {
      return klass.slice("no-bg-".length);
    }
  }
};


const setRemoteListenerForPaginateButtons = () => {
  const paginateLinks = document.querySelectorAll('a[data-remote]');
  for(let i = 0; i < paginateLinks.length; i++) {
    paginateLinks[i].addEventListener('ajax:success', () => {
      recordCardColors();
    })
  }
}


document.addEventListener("turbolinks:load", recordCardColors);
document.addEventListener("ajax:success", recordCardColors);
