export const hide = (element) => {
  if(!element.classList.contains('hidden')){
    element.classList.add('hidden');
  }
}

export const show = (element) => {
  if(element.classList.contains('hidden')){
    element.classList.remove('hidden')
  }
}

export const hideById = (id) => {
  const element = document.getElementById(id);
  if(element) hide(element);
}

export const showById = (id) => {
  const element = document.getElementById(id);
  if(element) show(element);
}

export const toggle = (element) => {
  if(element.classList.contains('hidden')){
    show(element);
  } else {
    hide(element);
  }
}

export const toggleById = (id) => {
  const element = document.getElementById(id);
  if(element.classList.contains('hidden')){
    show(element);
  } else {
    hide(element);
  }
}
